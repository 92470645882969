<template>
  <component
    :is="blok.heading_type"
    v-if="blok.heading_type"
    ref="headingTag"
    v-editable="blok"
    class="custom-heading"
    :class="[headingClass, animate && 'animate']"
    :aria-label="headingContent.replace(/(<([^>]+)>)/gi, '')"
  >
    <span v-if="headingContent" aria-hidden="true" v-html="headingContent" />
  </component>
</template>

<script lang="ts" setup>
import { ISbSchema } from '@storyblok/js';
import { CustomHeadingBlokProps } from '@/types';

// Plugins
const { $rteSchema } = useNuxtApp();

// Props
const props = defineProps<{
  blok: CustomHeadingBlokProps;
}>();

// Computed
// Heading Text
const heading = computed(() => {
  return props.blok.heading_text &&
    props.blok.heading_text.content &&
    props.blok.heading_text.content[0].content
    ? renderRichText(props.blok.heading_text, {
        schema: $rteSchema as ISbSchema | undefined,
      })
    : '';
});

// Stripping off the p tag because we're dropping the content into an h tag...using computed needed to retain reactivity due to use of <component>
const headingContent = computed(() => {
  return heading.value.replace(/<(\/)?p([^>]*)>/g, '');
});

// Heading Class...using computed needed to retain reactivity due to use of <component>
const headingClass = computed(() => {
  return props.blok.heading_style !== 'default' && props.blok.heading_style;
});

// Manage Hand-drawn Elements
const animate = ref(false);
const headingTag = ref<HTMLHeadingElement>();
const drawnItem = ref<NodeListOf<HTMLSpanElement>>();

drawIt(animate, headingTag, drawnItem);
</script>

<style lang="scss">
.custom-heading {
  position: relative;
  z-index: 0;

  .text-highlight {
    color: var(--highlight);
  }

  /* Class names in Storyblok: drawing-circle-1, drawing-circle-2, drawing-line-1, drawing-line-2 */
  [class*='drawing-'] {
    all: unset;
    position: relative;

    svg {
      position: absolute;
      transform-origin: left;
      z-index: -1;
    }

    path {
      stroke: var(--highlight);
      stroke-width: var(--width-stroke);

      @media all and #{$mq-s-max} {
        --width-stroke: 1.5;
      }
      @media all and #{$mq-m-min} {
        --width-stroke: 2;
      }
      @media all and #{$mq-l-min} {
        --width-stroke: 3;
      }

      /* Firefox specific to keep the stroke width at 3, due the slight scaling that will occur from not being able to use vector-effect="non-scaling-stroke" on teh path. */
      @supports (-moz-appearance: none) {
        --width-stroke: 3;
      }
    }
  }

  .drawing-circle-1 {
    svg {
      height: 92%;
      inset-block-start: 60%;
      inset-inline-start: 0;
      transform: translateY(-50%) scaleX(var(--scale, 1));
    }
  }

  .drawing-circle-2 {
    svg {
      height: 72.5%;
      inset-block-start: 50%;
      inset-inline-start: 0;
      transform: translateY(-50%) scaleX(var(--scale, 1));
    }
  }

  .drawing-line-1 {
    svg {
      height: 10%;
      inset-block-start: 85%;
      inset-inline-start: 0;
      transform: scaleX(var(--scale, 1));
    }
  }

  .drawing-line-2 {
    svg {
      height: 50%;
      inset-block-start: 70%;
      inset-inline-start: 0;
      transform: scaleX(var(--scale, 1));
    }
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .custom-heading {
    /* Trigger animation */
    &.animate {
      path {
        --dash-offset: var(--path-length, 0);

        animation: handdrawn 677ms var(--easing-1) var(--animation-delay, 0s)
          forwards;

        /* Dash the path to match the length of the path so it's invisible, then animate it back to visible. */
        stroke-dasharray: var(--path-length, 0);
        stroke-dashoffset: var(--dash-offset, 0);
      }
    }

    .drawing-circle-1 {
      --path-length: 1157;
    }

    .drawing-circle-2 {
      --path-length: 973;
    }

    .drawing-line-1 {
      --path-length: 605;
    }

    .drawing-line-2 {
      --path-length: 495;
    }

    /* Delay for Heroes */
    [class*='-hero'] & {
      [class*='drawing-'] {
        path {
          --animation-delay: calc(1750ms + var(--timing-hero-start-delay));
        }
      }
    }

    @keyframes handdrawn {
      from {
        opacity: 1;
      }

      to {
        opacity: 1;
        stroke-dashoffset: 0;
      }
    }
  }
}

/* To get around Chrome clip-path bug, the SVGs have to have style="opacity: 0;" added inline. So for those with prefers-reduced-motion set, we need to turn opacity back on. */
@media screen and (prefers-reduced-motion) {
  .custom-heading {
    path {
      animation: toggle-opacity forwards;
    }
    @keyframes toggle-opacity {
      to {
        opacity: 1;
      }
    }
  }
}

.green-txt {
  color: var(--bright-green) !important;
}
</style>
