import type { Ref } from 'vue';

export const drawIt = (
  animate: Ref<boolean>,
  headingTag: Ref<HTMLHeadingElement | undefined>,
  drawnItem: Ref<NodeListOf<HTMLSpanElement> | undefined>
) => {
  // Plugins
  const { $noMotionPreference } = useNuxtApp();

  // Variables & refs
  let observer: IntersectionObserver;

  // Lifecycle : onMounted
  onMounted(() => {
    // If there's a heading, proceed
    if (headingTag.value) {
      // Gather all elements that have a class that contains 'animate-'
      drawnItem.value = headingTag.value.querySelectorAll(
        '[class*="drawing-"]'
      );

      const isFirefox = navigator.userAgent.match(/firefox|fxios/i);

      drawnItem.value.forEach((item: HTMLSpanElement) => {
        // Class names in Storyblok: drawing-circle-1, drawing-circle-2, drawing-line-1, drawing-line-2
        // Circle 1
        if (item.className === 'drawing-circle-1') {
          item.innerHTML +=
            '<svg viewBox="0 0 374 147" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path style="opacity: 0;" d="M358.721 120.926C314.121 120.926 269.521 120.926 224.921 120.926C183.686 120.926 142.132 122.098 100.971 119.513C77.1489 118.017 52.7025 114.98 31.3475 105.192C12.5174 96.5604 -6.86716 80.764 6.34867 61.7128C17.6547 45.4146 37.715 35.2761 57.6896 28.5095C101.311 13.7325 150.155 7.25589 196.639 3.78372C250.255 -0.221198 317.022 -0.196344 359.467 33.6474C368.961 41.2176 373.504 53.2315 371.556 64.2817C368.454 81.8805 344.727 93.5883 328.424 101.21C298.419 115.237 264.891 123.416 231.786 129.982C191.456 137.981 150.19 144.161 108.732 144.946C86.3306 145.37 65.3809 143.359 44.1828 137.175C40.3699 136.062 35.0432 134.832 32.3177 132.486" stroke-linecap="round" /></svg>';
        }
        // Circle 2
        if (item.className === 'drawing-circle-2') {
          item.innerHTML +=
            '<svg viewBox="0 0 418 114" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path style="opacity: 0;" d="M2 83.8389C20.563 92.5856 39.8182 98.4826 59.9896 102.24C132.706 115.783 206.986 112.808 280.35 107.729C311.282 105.587 344.2 104.543 374.614 97.1872C385.322 94.5971 400.103 90.4294 407.927 81.9053C415.617 73.5265 417.096 59.7181 415.33 48.909C413.456 37.4394 405.724 30.6628 396.884 24.2709C367.146 2.76772 328.767 1.44725 293.428 2.12783C222.674 3.49047 149.744 20.2544 82.9386 43.1705C62.9136 50.0396 42.6878 58.1479 25.3809 70.6778C21.0425 73.8188 13.1784 79.3251 13.2278 85.9597C13.2747 92.2672 18.4788 96.7461 23.7152 98.9337C44.2932 107.53 68.1697 107.898 89.7246 111.908" stroke-width="3" stroke-linecap="round" /></svg>';
        }
        // Line 1
        if (item.className === 'drawing-line-1') {
          item.innerHTML +=
            '<svg viewBox="0 0 324 21" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path style="opacity: 0;" d="M1.5 11.7191C42.2349 11.6176 82.9024 10.4602 123.601 8.95508C179.323 6.89434 235.068 5.57206 290.825 4.57307C298.383 4.43767 305.941 4.43824 313.499 4.43824C315.81 4.43824 318.122 4.43824 320.433 4.43824C321.121 4.43824 322.607 4.94759 322.494 4.33712C322.367 3.65283 321.127 3.68009 320.395 3.46071C317.314 2.53681 316.202 2.39397 312.45 2.21353C289.085 1.08973 265.054 4.66185 241.917 6.8652C210.194 9.88634 178.911 12.1464 147.062 12.9326C120.868 13.5792 94.7288 13.568 68.5845 15.3596C61.0435 15.8763 53.4599 16.3508 45.9482 17.1798C44.4373 17.3465 42.3741 17.3886 40.9262 17.9214C40.2199 18.1812 39.5809 18.4538 39.2772 19" stroke-width="3" stroke-linecap="round" /></svg>';
        }
        // Line 2
        if (item.className === 'drawing-line-2') {
          item.innerHTML +=
            '<svg viewBox="0 0 274 51" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path style="opacity: 0;" d="M272.391 1.7373C267.872 10.0036 259.099 13.1674 250.721 16.1885C225.903 25.1378 200.212 28.5836 174.037 30.4667C130.669 33.5868 86.8485 32.8715 43.5584 29.024C41.9984 28.8853 1.37921 24.3984 2.42503 21.0774C3.00629 19.2316 8.47525 19.5626 9.77751 19.6673C32.4285 21.4883 54.7131 27.1049 77.0072 31.178C108.964 37.0163 140.987 40.4665 173.193 44.345C187.736 46.0964 202.24 47.9747 216.831 49.2715" stroke-width="3" stroke-linecap="round" /></svg>';
        }

        // Calculate scale based on item and svg width
        const svg = item.querySelector('svg');
        const scale = svg && item.offsetWidth / svg.clientWidth;

        // Add scale as inline style
        if (svg) {
          item.setAttribute('style', '--scale:' + scale);

          // Firefox has a bug with vector-effect="non-scaling-stroke, so is being excluded. Nothing about the effect is broken, just that the stroke width will slightly scale based on the length of the word.
          const path = svg.querySelector('path');
          if (!isFirefox && path) {
            path.setAttribute('vector-effect', 'non-scaling-stroke');
          }
        }
      });

      // To get values for stroke-dasharray and stroke-dashoffset, which is the total length of the path used in the CSS, add id='totalLength' to the path tag.
      // const tl = document.getElementById('totalLength');
      // console.info(tl.getTotalLength());
    }

    // Only set up IO if no motion preference and there's an item to animate
    if (
      $noMotionPreference === true &&
      drawnItem.value &&
      drawnItem.value.length !== 0
    ) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting === true) {
            animate.value = true;
          }
        });
      });

      drawnItem.value?.forEach((item: Element) => {
        observer.observe(item);
      });
    }
  });

  // Lifecycle : onBeforeUnmount
  onBeforeUnmount(() => {
    if (observer) observer.disconnect();
  });
};
